@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/Roboto-LightItalic.woff2') format('woff2'), url('../fonts/Roboto-LightItalic.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/Roboto-Light.woff2') format('woff2'), url('../fonts/Roboto-Light.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.woff') format('woff');
	font-display: swap;
}

